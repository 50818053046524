<template>
	<div class="pa-2">
		<!-- If meeting summaries exists-->
		<AppTable
			v-if="items.length"
			:headers="headers"
			:items="items"
			:actions="actions"
			:operation="operation"
			@create="createMeetingSummary()"
			@delete="executeDeletion"
			@explore="exploreMeeting"
		></AppTable>

		<!-- If no interviews are created -->
		<NoData
			v-else
			heading="app.no_meeting_summaries_yet"
			@init="createMeetingSummary()"
		></NoData>

		<!-- Confirmation box for delete operation -->
		<TheConfirmation
			v-model="dialog"
			:dialog="dialog"
			@cancel="dialog = false"
			@confirm="deleteMeeting()"
		></TheConfirmation>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { generateRandomString } from "@/utils/helpers";
import AppTable from "@/components/ui/AppTable.vue";
import NoData from "@/components/shared/NoData.vue";
import moment from "moment";
import TheConfirmation from "@/components/layout/TheConfirmation";

export default {
	name: "MeetingsList",

	data() {
		return {
			moment,
			dialog: false,
			selected_id: null,
		};
	},

	components: {
		AppTable,
		NoData,
		TheConfirmation,
	},

	computed: {
		...mapState({
			meetingSummaries: (state) => state.meetingSummary.list,
		}),

		...mapGetters({
			isMentor: "user/isMentor",
		}),

		headers() {
			return [
				"app.summary_no",
				"app.meeting_date",
				"app.meeting_participants",
				"app.actions",
			];
		},

		items() {
			let result = [];
			this.meetingSummaries.forEach((item) => {
				result.push([
					item.id,
					moment(item.meeting_date).format("DD/MM/YYYY"),
					item.participants,
				]);
			});

			return result;
		},

		actions() {
			return [
				{
					icon: "delete",
					color: "error",
					event_name: "delete",
				},
				{
					icon: "edit",
					color: "info",
					event_name: "explore",
				},
				{
					icon: this.$vuetify.rtl ? "left_arrow" : "right_arrow",
					color: "success",
					event_name: "explore",
				},
			];
		},

		operation() {
			return {
				event: "create",
			};
		},
	},

	mounted() {
		this.getMeetingSummaries();
	},

	methods: {
		async getMeetingSummaries() {
			try {
				this.$loader.start();

				await this.$store.dispatch("meetingSummary/list");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async createMeetingSummary() {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch("meetingSummary/create", {
					title: this.$t("app.my_meeting"),
					meeting_date: moment().format("YYYY-MM-DD h:mm"),
					participants: null,
				});

				// Push to the detail page
				this.exploreMeeting(response.id);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		exploreMeeting(meeting_id) {
			try {
				this.$router.push({
					name: this.isMentor
						? "MentorMeetingSummaryExplore"
						: "MeetingSummaryExplore",
					params: {
						id: meeting_id,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		executeDeletion(meeting_id) {
			try {
				this.selected_id = meeting_id;

				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async deleteMeeting() {
			try {
				this.$loader.start();

				// Delete Meeting
				await this.$store.dispatch("meetingSummary/delete", {
					meeting_id: this.selected_id,
				});

				this.dialog = false;

				this.selected_id = null;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
